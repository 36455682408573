import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Col75, Section, Container } from 'components/misc';
import { AsidePostsList, Post } from 'components/home';
import SEO from 'components/seo';

const FlexContainer = styled(Container)`
  display: flex;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    flex-direction: column;
  }
`;

const NewsHero = styled(BackgroundImage)`
  height: 370px;
`;

const NewsLayout = ({ data: { post, heroImage }})=> {
  return <>
    <SEO title={post.title} />
    <NewsHero tag="div" fluid={heroImage.childImageSharp.fluid} />
    <Section>
      <FlexContainer>
        <AsidePostsList />
        <Col75>
          <Post post={post} />
        </Col75>
      </FlexContainer>
    </Section>
  </>;
};

export const query = graphql`
  query PostAndImageQuery($id: String!) {
    post: datoCmsPost(id: {eq: $id}) {
      content
      slug
      title
      seo {
        title
        description
      }
      author {
        name
      }
      meta {
        firstPublishedAt
      }
    }
    heroImage: file(name: {eq: "news-hero"}) {
      childImageSharp {
        fluid(maxHeight: 370, quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

export default NewsLayout;
